import CustomUpload from "@/components/common/atom/Upload/Upload"
import { UploadFile } from "antd"
import "./UploadCADFile.scss"
import { useDispatch, useSelector } from "react-redux"
import { Dispatch, useRef, useState } from "react"
import { setIsUploading, IQuoteStore } from "@/store/quote"
import { RootState } from "@/store"
import { debounce } from "@/utils/functionHelper"
import { ModalSelectUnit } from "@/components"
import { EUnit } from "@/enums/unit.enum"
import { useUploadModels } from "@/hooks/useUploadModels"
import * as toast from "@/utils/Toast"

export interface IUploadCADFileProps {
  fileList: UploadFile[]
  setFileList: Dispatch<React.SetStateAction<UploadFile<any>[]>>
}

const UploadCADFile: React.FC<IUploadCADFileProps> = (
  props: IUploadCADFileProps
) => {
  const { fileList, setFileList } = props
  const { currentQuote } = useSelector<RootState, IQuoteStore>((s) => s.quote)

  const [open, setOpen] = useState(false)
  const [selectUnit, setSelectUnit] = useState<EUnit>(EUnit.MM)
  const filesRef = useRef<UploadFile[]>([])
  const dispatch = useDispatch()

  const { uploadModels } = useUploadModels()

  const uploadFile = async (unit?: EUnit) => {
    dispatch(setIsUploading(true))
    const files = filesRef.current
    try {
      const uploadJobId = currentQuote?.upload_job
      const orderId = currentQuote?.id
      if (!uploadJobId || !orderId) throw new Error()

      const { files: uploadedFiles, error } = await uploadModels(files, unit, {
        uploadJobId,
        orderId,
        uploadInterupted(failedFile) {
          setFileList((pre) => {
            return pre.map<UploadFile>(
              (e) => failedFile.find((f) => f.uid === e.uid) || e
            )
          })
        }
      })
      if (error) {
        toast.showError(error)
      }
      setFileList((pre) => {
        return pre
          .map<UploadFile>((e) => uploadedFiles[e.uid] || e)
          .filter((e) => e.status !== "done")
      })
    } catch (err: any) {
      toast.showError(err.message || err)
    } finally {
      dispatch(setIsUploading(false))
    }
  }

  const handleSubmitFile = async (files: UploadFile[]) => {
    const isContainStepFile = files.some((file) => {
      return /(stp|step|igs|iges)$/i.test(file.name)
    })
    const isContainAllStepFile = files.every((file) => {
      return /(stp|step|igs|iges)$/i.test(file.name)
    })

    filesRef.current = files
    if (isContainAllStepFile || (isContainStepFile && files.length === 1)) {
      uploadFile()
    } else {
      setOpen(true)
    }
  }

  const debounceMultipleUpload = debounce((uploadFiles: UploadFile[]) => {
    const toBeUpload = uploadFiles.filter((e) => !e.status)

    const uploadingFiles = toBeUpload.map((e) => {
      return {
        ...e,
        status: "uploading"
      }
    }) as UploadFile[]
    setFileList((pre) => [...pre, ...uploadingFiles])

    handleSubmitFile(toBeUpload)
  }, 500)

  const handleUploadFiles = (info: {
    file: UploadFile
    fileList: UploadFile[]
  }) => {
    debounceMultipleUpload(info.fileList)
  }

  const handleRemoveFile = async (file: UploadFile) => {
    setFileList((pre) => pre.filter((e) => e.uid !== file.uid))
  }

  const handleSelectedUnit = async () => {
    setOpen(false)
    uploadFile(selectUnit)
  }

  const handleCancel = () => {
    setOpen(false)
    filesRef.current.forEach((file) => handleRemoveFile(file))
  }

  return (
    <>
      <div className="upload-cad-file">
        <div className="content-box">
          <p className="title">Upload CAD file</p>
          <CustomUpload
            isShowUploadList={false}
            uploadHint="File types: STL, OBJ, WRL, STEP, STP,  IGES, IGS, 3MF, DXF, DWG, ZIP"
            files={fileList}
            onUploadFiles={handleUploadFiles}
            onRemoveFile={handleRemoveFile}
          />
        </div>
      </div>
      <ModalSelectUnit
        openModal={open}
        closeModal={handleCancel}
        onChangeUnit={(id) => setSelectUnit(id)}
        unit={selectUnit}
        onSelectedUnit={handleSelectedUnit}
      ></ModalSelectUnit>
    </>
  )
}

export default UploadCADFile
