import "./OrderToolbar.scss"
import { Button } from "@/components"
import { Size } from "@/enums/common.enum"
import { useCurrency } from "@/hooks/useCurrency"
import { Product } from "@/services/apiDigifabster/quote"
import { RootState } from "@/store"
import { IProductStore } from "@/store/product"
import { IQuoteStore } from "@/store/quote"
import { useSelector } from "react-redux"

interface IOrderToolbarProps {
  data: Product[]
  onCheckOut: () => void
  onSaveQuote: () => void
  onSubmitManualRequest: () => void
  isRequiresReview?: boolean
  DFMError?: boolean
}
function OrderToolbar({
  data,
  onCheckOut,
  onSaveQuote,
  isRequiresReview,
  onSubmitManualRequest,
  DFMError
}: IOrderToolbarProps) {
  // TODO: mission price in productEntity
  const { quoteDetail } = useSelector<RootState, IQuoteStore & IProductStore>(
    (s) => ({
      ...s.quote,
      ...s.product
    })
  )

  const { isUploadingFile } = useSelector<RootState, IQuoteStore>(
    (s) => s.quote
  )
  const { currency } = useCurrency()

  const disableBtnCheckout = data.length <= 0 || isUploadingFile || DFMError

  return (
    <div className="order-toolbar">
      <div className="order-toolbar-container">
        <div className="order-toolbar-box">
          <p>
            Order Total ({data.length} {data.length > 1 ? "items" : "item"}):{" "}
            {isRequiresReview ? (
              <span>Requires Review</span>
            ) : (
              <span>
                {currency}
                {quoteDetail?.models_naked_price?.toFixed(2)}
              </span>
            )}
          </p>
          <div className="order-toolbar-button">
            <>
              <div className="order-toolbar-button-text">
                Have Question? &nbsp;
                <span onClick={onSubmitManualRequest}>Ask for help</span>
              </div>
              <Button
                customSize={Size.EXTRA_LARGE}
                onClick={onCheckOut}
                disabled={disableBtnCheckout}
              >
                {isRequiresReview ? "Submit for review" : "Proceed to Checkout"}
              </Button>
            </>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderToolbar
