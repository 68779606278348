import { priceDisplay } from "@/utils/functionHelper"
import "./QuoteItem.scss"
import { LineItem } from "@/services/apiDigifabster/quote"
import { CustomBadge } from "@/components"
import { BadgeColor, BadgeType, Size } from "@/enums/common.enum"
import { DrawingIcon } from "@/assets"
import { calculateDeliveryDate } from "@/utils/timeFormatHelper"
import { useSelector } from "react-redux"
import { IQuoteStore } from "@/store/quote"
import { RootState } from "@/store"
import { IUserStore } from "@/store/user"
import { replacePostProductionTitle } from "@/utils/stringHelper"

interface IQuoteItemProps {
  item: LineItem
  currency: string
  deliveryDays?: number
}

function QuoteItem({ item, currency, deliveryDays }: IQuoteItemProps) {
  const { product, config, drawing_files } = item
  const { invoiceDetail } = useSelector<RootState, IQuoteStore>((s) => s.quote)
  const { userInfoNew } = useSelector<RootState, IUserStore>((s) => s.user)
  const country = userInfoNew?.country || ""
  return (
    <div className="quote-item-box">
      <div className="quote-item-box-col-1">
        <div>
          <img src={product.thumb_120x120} alt="" width={68} height={54} />
        </div>
        <div className="">
          <p>{product.title}</p>
          <div className="quote-item-box-col-1-size">
            <p>{`${product?.size?.x.toFixed(2) || 0} x 
                  ${product?.size?.y.toFixed(2) || 0}
                 x 
                  ${product?.size?.z.toFixed(2) || 0} ${product?.units || ""}
                `}</p>
          </div>
          <div className="quote-item-box-col-1-volume">
            <p>
              {product?.volume.toFixed(2)}{" "}
              {product?.units ? product?.units + "3" : ""}
            </p>
          </div>

          {!!drawing_files.length && (
            <div className="quote-drawings">
              {drawing_files?.map((item: any) => (
                <div key={item.id} className="quote-drawings-item">
                  <img width={12} height={12} src={DrawingIcon} alt="" />
                  <p>{item.name}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="quote-item-box-col-2">
        <p>{item.technology_title}</p>
        <p>
          {item.material_title} ,{item?.config?.color?.name_for_user}
        </p>
        <p>{config?.layer_thickness?.name_for_user || ""} </p>
        <p>
          {config?.filling?.name_for_user ||
            config?.filling?.value?.toFixed(1) + " percent" ||
            ""}
        </p>
      </div>
      <div className="quote-item-box-col-5">
        <p>Visual Inspection</p>
        {config?.post_production?.length > 0
          ? config.post_production.map((item) => (
              <p key={item.title}>
                {replacePostProductionTitle(item.title, country)}{" "}
                {item.countable && `(${item.quantity})`}
              </p>
            ))
          : ""}{" "}
      </div>
      <div className="quote-item-box-col-3">
        <p>{item.amount}</p>
      </div>
      <div className="quote-item-box-col-4">
        <div className="">
          <p>
            {currency}
            {priceDisplay(item.total_price)}
          </p>
          <p>
            {currency}
            {priceDisplay(item.unit_price)} / part
          </p>
        </div>

        <CustomBadge
          customClassName="quote-item-box-col-4-badge"
          content={`Ready soon as:  ${calculateDeliveryDate(
            deliveryDays || config?.lead_time?.days,
            invoiceDetail?.order?.date
              ? new Date(invoiceDetail?.order?.date)
              : new Date()
          )}`}
          color={BadgeColor.SUCCESS}
          type={BadgeType.TONAL}
          size={Size.SMALL}
        />
      </div>
    </div>
  )
}

export default QuoteItem
