import { api } from "../../apiInstance"
import {
  CreateOrderPayload,
  DeletePurchaselPayload,
  DeletePurchaseResponse,
  GetListQuoteApiArg,
  GetListQuoteApiResponse,
  GetQuoteApiArg,
  QuoteResponse,
  PreselectionPayload,
  PreselectionResponse,
  PurchaseProductPayload,
  UploadJobResponse,
  PurchaseProductResponse,
  UpdateProductPayload,
  UploadDrawingsResponse,
  UploadDrawingsPayload,
  DeleteDrawingsPayload,
  Quote,
  GetPriceProductPayload,
  GetPriceProductResponse,
  GetPricePostProductionPayload,
  GetPricePostProductionResponse,
  OrderDetailResponse,
  UploadAdditionalFilesPayload,
  UploadAdditionalFilesResponse,
  CreateManualRequestPayload,
  GetTaxRateResponse,
  GetTaxRatePayload,
  GetAdditionalFilesResponse,
  GetAdditionalFileArg,
  CreateManualRequestResponse,
  OrderCorrectionPayload,
  OrderCorrectionResponse,
  OrderStartUpPayload
} from "./type"

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    createUploadJob: build.mutation<UploadJobResponse, void>({
      query: () => ({
        url: `/api/dgfo/upload_job/`,
        method: "POST"
      })
    }),

    createOrder: build.mutation<QuoteResponse, CreateOrderPayload>({
      query: (argPayload) => ({
        url: `/api/dgfo/orders/`,
        method: "POST",
        body: {
          upload_job: argPayload.uploadJobId
        }
      })
    }),

    preselectModel: build.mutation<PreselectionResponse, PreselectionPayload>({
      query: (argPayload) => ({
        url: `/api/dgfo/preselection/`,
        method: "POST",
        body: {
          models_ids: argPayload.models
        }
      })
    }),

    purchaseProduct: build.mutation<
      PurchaseProductResponse,
      PurchaseProductPayload
    >({
      query: (argPayload) => ({
        url: `/api/dgfc/order/${argPayload.orderId}/quote/purchases`,
        method: "POST",
        body: argPayload.arg
      })
    }),

    updateProduct: build.mutation<
      PurchaseProductResponse,
      UpdateProductPayload
    >({
      query: (argPayload) => ({
        url: `/api/dgfc/order/${argPayload.orderId}/quote/purchases/${argPayload.productId}/`,
        method: "PATCH",
        body: argPayload.arg
      })
    }),

    getListQuote: build.query<GetListQuoteApiResponse, GetListQuoteApiArg>({
      query: (queryArg) => ({
        url: `/api/dgfo/orders/`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          ordering: queryArg.ordering,
          expand: queryArg.expand?.join(","),
          status__in: queryArg.status__in?.join(","),
          status__not__in: queryArg.status__not__in?.join(",")
        }
      })
    }),
    getQuote: build.query<QuoteResponse, GetQuoteApiArg>({
      query: (queryArg) => ({
        url: `/api/dgfc/order/${queryArg.quoteId}/quote/get-initial`
      })
    }),
    getQuoteDetail: build.query<OrderDetailResponse, GetQuoteApiArg>({
      query: (queryArg) => ({
        url: `/api/dgfc/order/${queryArg.quoteId}/quote/get-order-detail`
      })
    }),
    getDetail: build.query<OrderDetailResponse, GetQuoteApiArg>({
      query: (queryArg) => ({
        url: `/api/dgfc/order/${queryArg.quoteId}/quote/get-order-detail`
      })
    }),
    deletePurchase: build.mutation<
      DeletePurchaseResponse,
      DeletePurchaselPayload
    >({
      query: (argPayload) => ({
        url: `/api/dgfo/orders/${argPayload.orderId}/purchases/${argPayload.purchaseId}/`,
        method: "DELETE"
      })
    }),
    uploadDrawings: build.mutation<
      UploadDrawingsResponse,
      UploadDrawingsPayload
    >({
      query: (argPayload) => ({
        url: `/api/dgfo/orders/${argPayload.order_id}/purchases/${argPayload.purchase_id}/drawings/`,
        method: "POST",
        body: argPayload.arg
      })
    }),
    deleteDrawings: build.mutation<
      UploadDrawingsResponse,
      DeleteDrawingsPayload
    >({
      query: (argPayload) => ({
        url: `/api/dgfo/orders/${argPayload.order_id}/purchases/${argPayload.purchase_id}/drawings/${argPayload.drawings_id}/`,
        method: "DELETE"
      })
    }),
    getPriceProduct: build.mutation<
      GetPriceProductResponse,
      GetPriceProductPayload
    >({
      query: (queryArg) => ({
        url: `/api/dgfc/product/batch-price/material/`,
        method: "POST",
        body: queryArg
      })
    }),

    getPricePostProduction: build.mutation<
      GetPricePostProductionResponse,
      GetPricePostProductionPayload
    >({
      query: (queryArg) => ({
        url: `/api/dgfo/price/post_production/`,
        method: "POST",
        body: queryArg
      })
    }),
    getAdditionalFile: build.query<
      GetAdditionalFilesResponse[],
      GetAdditionalFileArg
    >({
      query: (queryArg) => ({
        url: `/api/dgfc/order/${queryArg.quoteId}/quote/attachments/`
      })
    }),
    uploadAdditionalFiles: build.mutation<
      UploadAdditionalFilesResponse[],
      UploadAdditionalFilesPayload
    >({
      query: (queryArg) => ({
        url: `/api/dgfo/orders/${queryArg.order_id}/attachments/`,
        method: "POST",
        body: queryArg.arg
      })
    }),
    createManualRequest: build.mutation<
      CreateManualRequestResponse,
      CreateManualRequestPayload
    >({
      query: (queryArg) => ({
        url: `/api/dgfc/order/${queryArg.order_id}/quote/manual-request/`,
        method: "POST",
        body: queryArg.arg
      })
    }),
    getTaxRate: build.mutation<GetTaxRateResponse, GetTaxRatePayload>({
      query: (queryArg) => ({
        url: `/api/dgfc/order/${queryArg.orderId}/quote/tax-rate/`,
        method: "POST",
        body: queryArg.arg
      })
    }),
    createPriceStartupCost: build.mutation<
      OrderCorrectionResponse,
      OrderStartUpPayload
    >({
      query: (queryArg) => ({
        url: `/api/dgfc/order/${queryArg.order_id}/quote/additional-startup-cost/`,
        method: "POST"
      })
    })
  }),

  overrideExisting: false
})
export { injectedRtkApi as quoteService }

export const {
  useCreateUploadJobMutation,
  useCreateOrderMutation,
  usePreselectModelMutation,
  useDeletePurchaseMutation,
  usePurchaseProductMutation,
  useUpdateProductMutation,
  useUploadDrawingsMutation,
  useGetPriceProductMutation,
  useGetPricePostProductionMutation,
  useDeleteDrawingsMutation,
  useUploadAdditionalFilesMutation,
  useCreateManualRequestMutation,
  useCreatePriceStartupCostMutation,
  useGetListQuoteQuery,
  useLazyGetListQuoteQuery,
  useGetQuoteDetailQuery,
  useGetQuoteQuery,
  useLazyGetQuoteDetailQuery,
  useLazyGetDetailQuery,
  useLazyGetQuoteQuery,
  useGetTaxRateMutation,
  useGetAdditionalFileQuery,
  useLazyGetAdditionalFileQuery
} = injectedRtkApi
