import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit"
import { technologyService } from "@/services/apiDigifabster/technology"
import { modelService } from "@/services/apiDigifabster/model"

export interface IMaterialConfig {
  note_for_user: string
  title: string
  uuid: string
  group_title?: string
  countable?: boolean
  price?: number
}

export interface IColor extends IMaterialConfig {
  color: string
}

export interface ILeadTime extends IMaterialConfig {
  tbd_quantity_threshold: string
}

export interface IMaterial {
  id: number
  title: string
  spec_sheet_url: string
  post_production: IMaterialConfig[]
  acceptDrawing: string
  color: IColor[]
  filling: IMaterialConfig[]
  layerThickness: IMaterialConfig[]
  leadTime: any[]
  tolerance: any[]
}
export interface ITechnology {
  id: 0
  title: string
  image: string
  note: string
  materials: IMaterial[]
}

export interface IAvailableTechnology {
  id: number
  materials: number[]
}

export enum EModelAnalysisStatus {
  LOADING,
  WARNING,
  ERROR,
  SUCCESS
}

export interface IModelAnalysis {
  status: EModelAnalysisStatus
  result?: {
    partSize?: boolean
    wallThickness?: boolean
    minimal_wall_thickness?: string
    thin_faces_file_url?: string
  }
}

export interface IProductStore {
  technologies: ITechnology[]
  suitableMaterial: Record<number, IAvailableTechnology[]>
  modelsAnalysis: Record<number, IModelAnalysis>
}
const initialState: IProductStore = {
  technologies: [],
  suitableMaterial: {},
  modelsAnalysis: {}
}
const productSlice = createSlice({
  name: "product",
  initialState: initialState,
  reducers: {},
  extraReducers: (builders: ActionReducerMapBuilder<IProductStore>) => {
    builders.addMatcher(
      technologyService.endpoints.technologyList.matchFulfilled,
      (state, { payload }) => {
        state.technologies = payload.results.map<ITechnology>((e) => ({
          id: e.id,
          title: e.title,
          image: e.image,
          note: e.note,
          materials: e.materials.map<IMaterial>((e) => ({
            id: e.id,
            title: e.title,
            acceptDrawing: e.accept_drawing,
            filling: e.filling,
            color: e.color,
            layerThickness: e.layer_thickness,
            spec_sheet_url: e.spec_sheet_url,
            leadTime: e.lead_time,
            tolerance: e.tolerance,
            post_production: e.post_production
          }))
        }))
      }
    )

    builders.addMatcher(
      modelService.endpoints.getSuitableMaterials.matchFulfilled,
      (state, { payload }) => {
        const suitableMaterial: Record<number, IAvailableTechnology[]> =
          state.suitableMaterial
        Object.keys(payload).map((e) => {
          const data = payload[e as any]
          const materialsObj = data.suitable_materials.reduce<
            Record<number, boolean>
          >((pre, cur) => ({ ...pre, [cur]: true }), {})
          const technologies = state.technologies.map<IAvailableTechnology>(
            (tech) => ({
              id: tech.id,
              materials: tech?.materials
                .filter((m) => materialsObj[m.id])
                .map((e) => e.id)
            })
          )

          suitableMaterial[e as any] = technologies
        })

        state.suitableMaterial = suitableMaterial
      }
    )

    builders.addMatcher(
      modelService.endpoints.checkWallThickness.matchPending,
      (state, { meta }) => {
        const request = meta.arg.originalArgs
        Object.keys(request).forEach((modelId: any) => {
          state.modelsAnalysis[modelId] = {
            status: EModelAnalysisStatus.LOADING
          }
        })
      }
    )

    builders.addMatcher(
      modelService.endpoints.checkWallThickness.matchRejected,
      (state, { meta }) => {
        const request = meta.arg.originalArgs
        Object.keys(request).forEach((modelId: any) => {
          state.modelsAnalysis[modelId] = {
            status: EModelAnalysisStatus.ERROR
          }
        })
      }
    )

    builders.addMatcher(
      modelService.endpoints.checkWallThickness.matchFulfilled,
      (state, { payload, meta }) => {
        const request = meta.arg.originalArgs
        Object.keys(payload).forEach((modelId: any) => {
          const itemRes = payload[modelId]?.[request.materials_ids[0]]
          if (!itemRes) return

          state.modelsAnalysis[modelId] = {
            status:
              itemRes.status === "passed"
                ? EModelAnalysisStatus.SUCCESS
                : EModelAnalysisStatus.WARNING,
            result: {
              ...state.modelsAnalysis[modelId]?.result,
              wallThickness: itemRes.status === "passed",
              minimal_wall_thickness: itemRes.minimal_wall_thickness,
              thin_faces_file_url: itemRes.thin_faces_file_url
            }
          }
        })
      }
    )
  }
})
export const {} = productSlice.actions
export const productReducer = productSlice.reducer
