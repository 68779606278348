import "@google/model-viewer"
import { useMemo } from "react"

interface IModelProps {
  style?: React.CSSProperties
  src: string
  wallThicknessFileUrl?: string
}
const Model: React.FC<IModelProps> = ({ style, src, wallThicknessFileUrl }) => {
  const encodedSrc = encodeURIComponent(src)
  const encodedWallThicknessFileUrl = wallThicknessFileUrl
    ? encodeURIComponent(wallThicknessFileUrl)
    : ""
  const url = useMemo(() => {
    return `https://app.digifabster.com/forge-labs-inc/widget/model-viewer?modelFileUrl=${encodedSrc}${
      !!encodedWallThicknessFileUrl.length
        ? `&wallThicknessFileUrl=${encodedWallThicknessFileUrl}`
        : ""
    }`
  }, [src, wallThicknessFileUrl])

  return (
    <div id="card" style={{ height: "100%", width: "100%" }}>
      <iframe
        style={{ height: "100%", width: "100%", border: "none" }}
        src={url}
      />
    </div>
  )
}

export default Model
