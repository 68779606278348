import { BadgeColor, BadgeType, Size } from "@/enums/common.enum"
import { Badge, BadgeProps, Flex } from "antd"
import "./Badge.scss"

interface CustomBadgeProps extends Omit<BadgeProps, "size"> {
  type?: BadgeType
  size?: Size
  color?: BadgeColor
  content: string
  top?: number
  right?: number
  prefix?: React.ReactNode
  customClassName?: string
  children?: React.ReactNode
}

const CustomBadge: React.FC<CustomBadgeProps> = ({
  type = BadgeType.SOLID,
  size = Size.EXTRA_SMALL,
  color = BadgeColor.ERROR,
  content,
  top = 0,
  right = 0,
  prefix,
  customClassName,
  children,
  ...props
}) => {
  return (
    <Badge
      {...props}
      className={`custom-badge-${type}-${size}-${color} ${customClassName}`}
      count={
        <div>
          <Flex
            className="custom-badge-container"
            style={{ padding: "2px 8px" }}
            justify="center"
            align="center"
          >
            {prefix && <div className="icon-circle">{prefix}</div>}
            {content}
            {children}
          </Flex>
        </div>
      }
      offset={[top, right]}
    ></Badge>
  )
}

export default CustomBadge
