import { IViewerModalData } from "@/interfaces/order.interface"
import "./DFMCheck.scss"
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined
} from "@ant-design/icons"
import { tagStatusClass } from "../ViewerModal/ViewerModal"
import { EModelAnalysisStatus } from "@/store/product"

interface DFMCheckProps {
  data: IViewerModalData[]
}

function DFMCheck({ data }: DFMCheckProps) {
  return (
    <div className="dfm-check-container">
      <div className="dfm-check-items">
        {data.map((item, index) => (
          <div className="dfm-check-item" key={index}>
            {item.isDisabled ? (
              <>
                <ExclamationCircleOutlined
                  style={{ color: "var(--bg-gray-normal)" }}
                  width={24}
                  height={24}
                />
              </>
            ) : (
              <img
                src={
                  tagStatusClass[item?.status || EModelAnalysisStatus.LOADING]
                    .icon
                }
                width={24}
                height={24}
              />
            )}
            <div className="dfm-check-item-text">
              <h5>{item.title}</h5>
              <p>{item.value}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="dfm-check-warning">
        <ExclamationCircleOutlined
          color="var(--bg-gray-stronger)"
          width={24}
          height={24}
        />
        <p>
          Indicates check cannot be determined automatically. See full design
          guidelines for more information.
        </p>
      </div>
    </div>
  )
}

export default DFMCheck
