import { useMemo, useState } from "react"
import { ButtonHierarchy, Size, Type } from "@/enums/common.enum"
import { EditOutlined, DeleteOutlined } from "@ant-design/icons"
import "./ProductItem.scss"
import { Button, CustomCheckbox, NumberField } from "@/components"
import {
  LineItem,
  useDeletePurchaseMutation,
  useUpdateProductMutation
} from "@/services/apiDigifabster/quote"
import { RootState } from "@/store"
import { IQuoteStore } from "@/store/quote"
import { useSelector } from "react-redux"
import { ModelResponse } from "@/services/apiDigifabster/model"
import {
  BlockIcon,
  LoadingIcon,
  OrderReviewIcon,
  SuccessToastIcon,
  WarningDFMIcon
} from "@/assets"
import ViewerModal from "../ViewerModal/ViewerModal"
import { useAppLoading } from "@/hooks/useLoading"
import { debounce, priceDisplay } from "@/utils/functionHelper"
import {
  EModelAnalysisStatus,
  IModelAnalysis,
  IProductStore
} from "@/store/product"
import { useCurrency } from "@/hooks/useCurrency"
import Skeleton from "react-loading-skeleton"
import { calculateDeliveryDate } from "@/utils/timeFormatHelper"
import { IUserStore } from "@/store/user"
import { replacePostProductionTitle } from "@/utils/stringHelper"
import { IPostProductionData } from "@/interfaces/editSpecifications.interface"

interface ProductItemProps {
  isSelected: boolean
  isProcessing: boolean
  onSelect: (selected: boolean) => void
  onEditItem: (id: number) => void
  item: LineItem
  isEditable?: boolean
  openDrawer?: (id: number) => void
  deliveryDays?: number
}

export const buttonStatusClass = {
  [EModelAnalysisStatus.LOADING]: "button-loading",
  [EModelAnalysisStatus.SUCCESS]: "button-success",
  [EModelAnalysisStatus.WARNING]: "button-warning",
  [EModelAnalysisStatus.ERROR]: "button-error"
}

export const tagStatusClass = {
  [EModelAnalysisStatus.LOADING]: {
    text: "Checking for Manufacturing Issues....",
    icon: OrderReviewIcon,
    className: "corner-bottom-right-bevel-loading"
  },
  [EModelAnalysisStatus.SUCCESS]: {
    text: "Ready To Order",
    icon: SuccessToastIcon,
    className: "corner-bottom-right-bevel-success"
  },
  [EModelAnalysisStatus.WARNING]: {
    text: "Manufacturing Issues Detected",
    icon: WarningDFMIcon,
    className: "corner-bottom-right-bevel-warning"
  },
  [EModelAnalysisStatus.ERROR]: {
    text: "Manufacturing Issues Detected",
    icon: BlockIcon,
    className: "corner-bottom-right-bevel-error"
  }
}

function ProductItem(props: ProductItemProps) {
  const {
    isSelected,
    onSelect,
    onEditItem,
    isEditable = true,
    deliveryDays,
    openDrawer,
    isProcessing,
    item: {
      id,
      config_formatted_json,
      material_title,
      total_price,
      config,
      material,
      unit_price,
      amount,
      product
    }
  } = props
  const { technologies } = useSelector<RootState, IProductStore>(
    (s) => s.product
  )
  const { userInfoNew } = useSelector<RootState, IUserStore>((s) => s.user)
  const country = userInfoNew?.country || ""
  const [imageLoaded, setImageLoaded] = useState(false)

  const isRequiresReview = technologies
    .find((e) => e.materials.find((m) => m.id === material))
    ?.materials.find((m) => m.id === material)
    ?.post_production?.some((n) =>
      config?.post_production?.some(
        (p) => p.uuid === n.uuid && n.note_for_user === "Requires manual review"
      )
    )

  const tech = useMemo(
    () => config_formatted_json?.find((item) => item.key === "Technology"),
    [config_formatted_json]
  )
  const { currentQuote } = useSelector<RootState, IQuoteStore>((s) => s.quote)
  const currentModel = useSelector<RootState, ModelResponse | undefined>(
    (s) => s.quote.currentModels[product?.id || 0]
  )

  const modelAnalysis = useSelector<RootState, IModelAnalysis | undefined>(
    (s) => s.product.modelsAnalysis[product?.id || 0]
  )
  const { currency } = useCurrency()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [deletePurchase, { isLoading: isLoadingDelete }] =
    useDeletePurchaseMutation()
  const [updateProduct, { isLoading: isLoadingUpdate }] =
    useUpdateProductMutation()

  useAppLoading([isLoadingDelete])

  const handleDeleteItem = (id: number) => {
    deletePurchase({
      orderId: currentQuote?.id || 0,
      purchaseId: id
    })
  }

  const immediatelyUpdateProduct = (
    value: number,
    event?: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!currentQuote || !currentQuote.id || !id) return

    const configPayload = {
      layer_thickness: config?.layer_thickness?.uuid,
      filling: config?.filling?.uuid,
      color: config?.color?.uuid,
      lead_time: config?.lead_time?.uuid,
      post_production: config?.post_production?.map((item) => {
        return {
          uuid: item?.uuid,
          quantity: item?.quantity
        } as IPostProductionData
      })
    }
    updateProduct({
      orderId: currentQuote?.id,
      productId: id,
      arg: {
        count: value,
        config: configPayload,
        material_id: material,
        model_id: product?.id
      }
    })
    event?.target?.blur()
  }

  const debounceUpdateProduct = debounce((event, value) => {
    immediatelyUpdateProduct(event, value)
  }, 1000)

  const handleChangeQuanity = (
    value: number,
    event?: React.ChangeEvent<HTMLInputElement>,
    { isDebounce = true } = {}
  ) => {
    if (!currentQuote || !currentQuote.id || !id) return

    isDebounce
      ? debounceUpdateProduct(value, event)
      : immediatelyUpdateProduct(value, event)
  }

  const stopCollapseEvent = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
  }

  const statusTag = useMemo(() => {
    if (isRequiresReview) return EModelAnalysisStatus.WARNING
    return modelAnalysis?.status || EModelAnalysisStatus.ERROR
  }, [modelAnalysis, isRequiresReview])

  const preventPointerCursor = // prevent pointer cursor when the item is not editable and in loading state
    !isEditable || isLoadingUpdate ? " uneditable" : ""

  return (
    <>
      <div
        className={`col${preventPointerCursor}`}
        onClick={() => {
          if (!isEditable || isLoadingUpdate) return // prevent open drawer when the item is not editable and in loading state
          openDrawer?.(id)
        }}
      >
        <div className={`product-tag`}>
          <p>
            <img
              width={16}
              height={16}
              src={
                isProcessing
                  ? tagStatusClass[EModelAnalysisStatus.LOADING]?.icon
                  : tagStatusClass[statusTag]?.icon
              }
              alt=""
            />
            <span>
              {isRequiresReview
                ? "Requires Manual Review"
                : isProcessing
                ? tagStatusClass[EModelAnalysisStatus.LOADING]?.text
                : tagStatusClass[statusTag]?.text}
            </span>
          </p>

          <div
            className={`corner-bottom-right-bevel  ${
              isProcessing
                ? tagStatusClass[EModelAnalysisStatus.LOADING]?.className
                : tagStatusClass[statusTag].className
            }`}
          ></div>
        </div>
        <div className={`container${preventPointerCursor}`}>
          <div className="col-1">
            {/* <CustomCheckbox
              checked={isSelected}
              onChange={(e) => onSelect(e.target.checked)}
              onClick={(e) => stopCollapseEvent(e)}
            /> */}
            <div
              className="product-viewer"
              onClick={(e) => {
                setIsModalOpen(true)
                stopCollapseEvent(e)
              }}
            >
              {currentModel?.thumb_120x120 && (
                <img
                  width={120}
                  height={120}
                  src={currentModel?.thumb_120x120}
                  alt={currentModel?.title}
                  style={{ display: imageLoaded ? undefined : "none" }}
                  onLoad={() => {
                    setImageLoaded(true)
                  }}
                />
              )}

              {!imageLoaded && <Skeleton width={120} height={120} />}
            </div>

            {currentModel ? (
              <div className="col-2-text">
                <div className="col-2-text-filename">
                  {currentModel?.title || ""}
                </div>
                <div className="col-2-size">
                  {currentModel?.size && (
                    <p>
                      {currentModel?.size?.x?.toFixed(2) || 0} x
                      {currentModel?.size.y?.toFixed(2) || 0} x
                      {currentModel?.size.z?.toFixed(2) || 0}
                      {currentModel?.units || ""}
                    </p>
                  )}
                </div>
                <div className="col-2-volume">
                  <p>
                    {currentModel?.volume?.toFixed(2)}{" "}
                    {currentModel?.units ? currentModel?.units + "3" : ""}
                  </p>
                </div>
                {modelAnalysis ? (
                  <div>
                    <Button
                      customSize={Size.EXTRA_SMALL}
                      hierarchy={ButtonHierarchy.TONAL}
                      customType={Type.NEUTRAL}
                      customClassName={[
                        "button",
                        buttonStatusClass[modelAnalysis.status] ||
                          "button-loading"
                      ].join(" ")}
                      onClick={(e) => {
                        setIsModalOpen(true)
                        stopCollapseEvent(e)
                      }}
                    >
                      {modelAnalysis.status === EModelAnalysisStatus.LOADING
                        ? "Performing DFM Analysis..."
                        : "View DFM Analysis"}
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <div className="col-2-text">
                <div className="col-2-text-filename">{product?.title}</div>
                <div className="col-2-size">
                  <p>
                    <Skeleton width={120} height={20} />
                  </p>
                </div>
                <div className="col-2-volume">
                  <p>
                    <Skeleton width={60} height={20} />
                  </p>
                </div>
              </div>
            )}
          </div>

          <div className="col-3">
            <p>{tech?.value || ""}</p>
            <p>
              {material_title || ""}, {config?.color?.name_for_user || ""}
            </p>
            <p>{config?.layer_thickness?.name_for_user || ""} um</p>
            <p>
              {config?.filling?.name_for_user ||
                config?.filling?.value?.toFixed(1) + " percent" ||
                ""}
            </p>
            <p>{}</p>
          </div>
          <div className="col-4">
            <p>Visual Inspection</p>
            {config?.post_production?.length > 0 && (
              <>
                {config?.post_production?.map((item, index) => (
                  <div key={`${index} post`}>
                    <p>
                      {replacePostProductionTitle(item.title, country)}{" "}
                      {item.countable && `(${item.quantity})`}
                    </p>
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="col-5" onClick={(e) => e.stopPropagation()}>
            <NumberField
              isDebounce={false}
              value={amount}
              setValue={handleChangeQuanity}
              disable={isLoadingUpdate}
            />
          </div>
          <div className={`col-6${preventPointerCursor}`}>
            <div className="col-6-text">
              <p>
                {currency}
                {priceDisplay(total_price || 0)}
              </p>
              <p>
                {currency}
                {priceDisplay(unit_price || 0)} / part
              </p>
            </div>
            {isEditable && !isLoadingUpdate ? ( // enable edit button only when the item is not in loading state
              <div className="col-6-action">
                <Button
                  hierarchy={ButtonHierarchy.LINK}
                  onClick={() => onEditItem(id)}
                  customSize={Size.MEDIUM}
                  customClassName="edit-btn"
                >
                  <EditOutlined /> Edit Configuration
                </Button>
                <Button
                  hierarchy={ButtonHierarchy.LINK}
                  onClick={(e) => {
                    handleDeleteItem(id)
                    stopCollapseEvent(e)
                  }}
                >
                  <DeleteOutlined />
                </Button>
              </div>
            ) : (
              <div className="icon-loading">
                <img src={LoadingIcon} />
              </div>
            )}
          </div>
        </div>
        <div className="date-delivery">
          <p>Ready soon as:</p>
          <p>{calculateDeliveryDate(deliveryDays || config.lead_time.days)}</p>
        </div>
      </div>

      <ViewerModal
        modelTitle={product?.title}
        isModalOpen={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        currentPart={currentModel}
        loading={false}
        analysis={modelAnalysis}
        technology={config_formatted_json}
        product={product}
        material={material}
        config={config}
      />
    </>
  )
}

export default ProductItem
